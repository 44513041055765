import React, { Component } from "react";
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

//Miscellaneous
import moment from "moment";
import Swal from "sweetalert2";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Fade, Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { default as ReactSelect } from "react-select";

//Components
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import DataTable from "../../../components/Datatable/DataTable";
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import Button from "../../../components/Button/Button";
import { isDesktop } from "react-device-detect";

//Icons
import { Icon } from "@iconify/react";

// Actions
import * as actions from "../../../store/ducks/history/actions";
import MobileBettingHistory from "./MobileBettingHistory";

const gameCategories = [
    { id: "filterAll", value: "", label: "All" },
    { id: "filterLC", value: "ca", label: "Live Casino" },
    { id: "filterSports", value: "sp", label: "Sport" },
    { id: "filterSlots", value: "sl", label: "Slots" },
    { id: "filterGames", value: "ga", label: "Games" },
    { id: "filterP2P", value: "p2", label: "P2P" },
    { id: "filterLottery", value: "ke", label: "Lottery" },
];

const filterDate = [
    { id: "filterToday", value: "0", label: "today" },
    { id: "filterYtd", value: "1", label: "yesterday" },
    { id: "filter7Day", value: "7", label: "last-7-days" },
    { id: "filter30Days", value: "30", label: "last-30-days" },
];

const gameProviders = [
    { id: "all", value: "", label: "", icon: "" },
    { id: "filterAb", value: "ab", label: "ALLBET", icon: "ab-icon.webp" },
    { id: "filterAg", value: "ag", label: "ASIA GAMING", icon: "ag-icon.webp" },
    { id: "filterBti", value: "bti", label: "BTI", icon: "bti-icon.png" },
    { id: "filterEa", value: "ea", label: "EA GAMING", icon: "ea-icon.webp" },
    { id: "filterEvo", value: "evo", label: "EVOLUTION GAMING", icon: "evo-icon.webp" },
    { id: "filterGpi", value: "gp", label: "GAMEPLAY INT", icon: "gpi-icon.webp" },
    { id: "filterIm", value: "im", label: "INPLAY MATRIX", icon: "im-icon.png" },
    { id: "filterIsb", value: "isb", label: "I SOFT BET", icon: "isb-icon.webp" },
    { id: "filterMg", value: "mg", label: "MICRO GAMING", icon: "mg-icon.webp" },
    { id: "filterNet", value: "net", label: "NETENT", icon: "net-icon.webp" },
    { id: "filterPp", value: "pp", label: "PRAGMATIC PLAY", icon: "pp-icon.webp" },
    { id: "filterPt", value: "pt", label: "PLAYTECH", icon: "pt-icon.webp" },
    { id: "filterQt", value: "qt", label: "QTECH", icon: "qt-icon.webp" },
    { id: "filterSa", value: "sa", label: "SA GAMING", icon: "sa-icon.webp" },
    { id: "filterSb", value: "sb", label: "SABA", icon: "sb-icon.webp" },
    { id: "filterTf", value: "tf", label: "TF GAMING", icon: "tf-icon.png" },
    { id: "filterSbo", value: "sbo", label: "SBOBET", icon: "sbo-icon.webp" },
    { id: "filterSg", value: "sg", label: "SPADE GAMING", icon: "sg-icon.webp" },
    { id: "filterSp", value: "sp", label: "SIMPLE PLAY", icon: "sp-icon.webp" },
    { id: "filterTtg", value: "ttg", label: "TOP TREND GAMING", icon: "ttg-icon.webp" },
    { id: "filterQs", value: "qs", label: "QUICKSPIN", icon: "qs-icon.webp" },
    { id: "filterDg", value: "dg", label: "DREAM GAMING", icon: "dg-icon.webp" },
    { id: "filterPng", value: "png", label: "PLAYNGO", icon: "png-icon.webp" },
    { id: "filterPng", value: "pg", label: "POCKET GAMES SOFT", icon: "pg-icon.webp" },
]
class BettingHistory extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            selectedFilterType: '',
            historyTab: 'betting_summary',
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            currentFilterDate: 0,
            transaction_date: '',
            game_type: '',

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalRecords: 0,

            /* api result array */
            data: [],
            total: [],

            /* skeleton loading */
            loading: true,
            dates: null,

            /* mobile */
            showDateSelect: false,
            showDate: '',
            selectedDate: '',

            spin: false,

            /* game provider */
            game_provider: ''
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.funcGetList(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) {
            if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success') {
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                    total: nextProps.rs.data.total,
                    disableSearchButton: false,
                });
            }
            else
            {
                let msg = null;
                msg = "<div>";
                nextProps.rs.message.forEach((val) => {
                    msg += "<p>" + val + "</p>";
                });
                msg += "</div>";

                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">Aa">' : "",
                    icon: "error",
                    title: t("global.sorry"),
                    html: msg,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                                container: "swal-mobile-container sorry",
                                icon: "swal-sorry-icon",
                            }
                        : [],
                }).then(() => {
                    this.setState({
                        loading: false,
                        disableSearchButton: false,
                    });
                });

                
            }
        }

        return true;
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    onInputChange = (event) => {
        this.setState({
            selectedFilterType: event.target.value
        });
        // console.log(event.target.name+' | '+event.target.value);
        if (event.target.id === 'transaction_date') {
            const date = (event.target.value !== '') ? event.target.value.split(" - ") : null;
            this.setState({
                start_date: (date[0]) ? moment(date[0]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                end_date: (date[1]) ? moment(date[1]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                transaction_date: event.target.value,
            });

            const startDate = moment(this.state.start_date);
            const endDate = moment(this.state.end_date);
            const diff = endDate.diff(startDate, 'days');

            // Only allow to get data 31 days
            if (diff > 31) {
                this.setState({
                    disableSearchButton: true
                })
            } else {
                this.setState({
                    disableSearchButton: false
                })
            }
        }
        else {
            this.setState({
                [event.target.name]: event.target.value,
            }, () => {
                this.searchHandler();
            });
        }
    }

    handleGameProviderChange = (e) => {
        let value = e.value || e.target.defaultValue;

        if (this.state.game_provider !== value) {
            this.setState({
                game_provider: value,
            }, () => {
                this.searchHandler();
            });
        }
    }

    searchHandler = () => {
        this.setState({
            activePage: 1,
            data: [],
            loading: true,
            disableSearchButton: true
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    filterDayRange = (val) => {
        // let days = Number(event.target.value);
        let days = val;
        let startDate = moment().subtract(days, "days").format("YYYY-MM-DD");
        let endDate = moment().format("YYYY-MM-DD");
        let transactionDate = `${moment(startDate).format("MM/DD/YYYY")} - ${moment(endDate).format("MM/DD/YYYY")}`;

        this.setState({
            start_date: startDate,
            end_date: endDate,
            transaction_date: transactionDate,
            filterRange: days
        }, () => {
            let startDate = moment(this.state.start_date);
            let endDate = moment(this.state.end_date);
            let dayRange = endDate.diff(startDate, 'days');
            let disableButton = null;
            if (!startDate.isSameOrBefore(endDate) || !endDate.isSameOrAfter(startDate) || dayRange > 92) {
                disableButton = true;
            } else {
                disableButton = false;
                const listingArr = this.getFilterArray(this.state.historyTab);
                this.props.funcGetList(listingArr);
            }
            this.setState({
                disableSearchButton: disableButton
            })

            this.searchHandler();
        });
    }

    getFilterArray = () => {
        const listingArr = {};
        listingArr.page = this.state.activePage;
        listingArr.limit = this.state.limit;
        // listingArr.order = JSON.stringify(this.state.order);
        listingArr.start_date = this.state.start_date;
        listingArr.end_date = this.state.end_date;
        listingArr.game_type = this.state.game_type;
        listingArr.country_id = this.props.currentUser.country_id;
        listingArr.lang_id = this.props.currentUser.language_id;
        listingArr.transaction_type = this.state.historyTab;
        listingArr.game_provider = this.state.game_provider;
        return listingArr;
    }

    datatablesChangeHandler = (val, tab, act) => {
        this.setState({
            data: [],
            loading: true,
            [act]: val
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    //Mobile//

    toggleShowDateSelect = (e, show) => {
        if (this.state.showDateSelect === false) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            showDateSelect: !this.state.showDateSelect,
        });
        if (show === 'startDate') {
            this.setState({
                showDate: 'startDate',
                selectedDate: this.state.start_date,
                disableSearchButton: false,
            })
        } else if (show === 'endDate') {
            this.setState({
                showDate: 'endDate',
                selectedDate: this.state.end_date,
                disableSearchButton: false,
            })
        }
    }
    onChangeDateHandler = (value) => {
        if (this.state.showDate === 'startDate') {
            this.setState({
                start_date: `${value.years}-${value.months}-${value.days}`,
            })
        } else if (this.state.showDate === 'endDate') {
            this.setState({
                end_date: `${value.years}-${value.months}-${value.days}`,
            })
        }
    }

    onChangeFilterDateHandler = (value, e) => {
        this.setState({
            currentFilterDate: value
        });
    }

    onResetHandler = () => {
        this.setState({
            selectedFilterType: '',
            game_type:'',
            game_provider:'',
            historyTab: 'betting_summary',
            currentFilterDate: 0,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            transaction_date: "",
            spin: true
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });

        setTimeout(() => this.setState({ spin: false }), 2000);
    }

    render() {
        // console.log(this.state.total);
        const { t, i18n } = this.props;
        const icons = this.imageImport(require.context("../../../assets/images/profile/icons", false, /\.(png|jpe?g|svg)$/));
        const providers = this.imageImport(require.context("../../../assets/images/profile/provider-icon", false, /\.(png|jpe?g|svg|webp)$/));
        const resetIcon = this.imageImport(
            require.context("../../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
        );


        let rsData = [];
        let gn_val = "";
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        const columns = [
            { text: t('history.update-date'), dataField: 'update_date' },
            { text: t('history.game-provider'), dataField: 'game_provider' },
            { text: t('history.category'), dataField: 'category' },
            { text: t('history.game-name'), dataField: 'gameName' },
            { text: t('history.bet-amount'), dataField: 'bet_amount' },
            { text: t('history.payout'), dataField: 'payout' },
            { text: t('history.turnover'), dataField: 'turnover' },
            { text: t('history.r77-coin'), dataField: 'coin' },
            // {text: '', dataField: 'status'},
        ];

        if (this.state.data) {
            rsData = this.state.data.map((val, idx) => {
                if (val.gameCode !== null) {
                    if (val.title === null && i18n.exists(`games:${val.gameVendorSlug}.${val.gameCode}`)) {                        
                        gn_val = t(`games:${val.gameVendorSlug}.${val.gameCode}`);
                    }
                    else if (val.title === null && !i18n.exists(`games:${val.gameVendorSlug}.${val.gameCode}`)) {                        
                        gn_val = val.gameCode;
                    }
                    else {
                        gn_val = val.title;
                    } 
                } else {
                    gn_val = (val.result === 'bonus') ? t('history.bonus') : '';
                }

                return {
                    no: idx,
                    update_date: val.update_date,
                    game_provider: t(`vendor.${val.gameVendorSlug}`),
                    category: t(`game-type.${val.category}`),
                    gameName: gn_val,
                    bet_count: val.bet_count,
                    bet_amount: new Intl.NumberFormat("en-US", num_format).format(val.bet_amount),
                    turnover: new Intl.NumberFormat("en-US", num_format).format(val.turnover),
                    payout: new Intl.NumberFormat("en-US", num_format).format(val.payout),
                    coin: new Intl.NumberFormat("en-US", num_format).format(val.coin),
                };
            });
        }

        const fadeUp = keyframes`
            from {
            opacity: 0;
            transform: translate3d(0, 50px ,0);
            }

            to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            }
        `;

        return (
            <Auxiliary>
                {isDesktop ? (
                    <section className="pf-dashboard">
                        <div className="x-container">
                            <ProfileSideBar />
                            <Reveal className="pf-fade-col" keyframes={fadeUp} duration={1000} triggerOnce>
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="pd-card-col">
                                            <h4 className="mb-2">{t('profile-sidebar.betting-history')}</h4>
                                            <p>{t('history.notice-1')}</p>
                                            <div className="pd-content">
                                                <div className="filters-col">
                                                    <div className="filters-tp-panel">
                                                        <div className="filters-tp-col">
                                                            <label className="col-form-label">{t('history.type')}:</label>
                                                            <div className="filters-group">
                                                                {gameCategories &&
                                                                    gameCategories.map((item, index) => (
                                                                        <div className="form-check form-check-inline filter" key={index}>
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name="game_type"
                                                                                id={item.id}
                                                                                value={item.value}
                                                                                defaultChecked={index === 0}
                                                                                checked={item.value === this.state.selectedFilterType}
                                                                                onClick={(e) => this.onInputChange(e)}
                                                                            />
                                                                            <label className="btn form-check-label" htmlFor={item.id}>
                                                                                {item.value === '' ? t(`global.all`) : t(`game-type.${item.value}`)}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                        <div className="filters-tp-col">
                                                            <label className="col-form-label wrap-text">{t('profile.game-provider')}:</label>
                                                            <ReactSelect
                                                                classNamePrefix="react-select"
                                                                className="filter-provider"
                                                                placeholder={t("profile.search-by-word")}
                                                                name="game_provider"
                                                                options={gameProviders}
                                                                onChange={this.handleGameProviderChange}
                                                                value={(this.state.game_provider !== '') ? gameProviders[this.state.game_provider] : ''}
                                                                formatOptionLabel={(item) => (
                                                                    <>
                                                                        {item.icon && <img src={(providers[`${item.icon}`])} alt={item.label} />}
                                                                        <p>{item.label}</p>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="filters-bt-panel">
                                                        <div className="filters-bt-col">
                                                            <label className="col-form-label">{t('history.date')}:</label>
                                                            <div className="filters-group">
                                                                {filterDate &&
                                                                    filterDate.map((item, index) => (
                                                                        <div className="form-check form-check-inline filter" key={index}>
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name="filterDate"
                                                                                id={item.id}
                                                                                value={item.value}
                                                                                defaultChecked={index === 0}
                                                                                checked={index === this.state.currentFilterDate}
                                                                                onClick={(e) => this.onChangeFilterDateHandler(index, e)}
                                                                            />
                                                                            <label className="btn form-check-label" htmlFor={item.id} style={{ maxWidth: '75px' }} onClick={() => this.filterDayRange(item.value)}>
                                                                                {t(`history.${item.label}`)}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                        <div className="filters-bt-col">
                                                            <label className="col-form-label">{t('history.time')}:</label>
                                                            <div className="input-group right">
                                                                <DateTimePicker
                                                                    id="transaction_date"
                                                                    name="transaction_date"
                                                                    className="form-control"
                                                                    autoUpdateInput={true}
                                                                    autoApply={true}
                                                                    format="MM/DD/YYYY"
                                                                    value={this.state.transaction_date}
                                                                    onChange={this.onInputChange}
                                                                />
                                                                <span className="input-group-text">
                                                                    <Icon icon="uil:calendar-alt" />
                                                                </span>
                                                            </div>
                                                            <Button
                                                                typeButton="button"
                                                                classValue="btn btn-gradient-blue"
                                                                idValue="search"
                                                                buttonName={t('global.check')}
                                                                clicked={() => this.searchHandler(this.state.historyTab)}
                                                                disabled={this.state.disableSearchButton}
                                                            />
                                                            <div className="reset-btn">
                                                                <img className={`${this.state.spin ? "spin" : ""} `} src={resetIcon["rotate-icon.svg"]} alt="reset" onClick={this.onResetHandler} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pd-card-col">
                                            <div className="pd-tbs-col">
                                                <img src={icons["info.svg"]} alt="info-icon" />
                                                <p>{t('history.a-total-of')} {this.state.total && (this.state.total.totalCount || 0)} {t('history.bets')}</p>

                                                <span className="mx-2">|</span>
                                                <p>{t('history.turnover')}: {new Intl.NumberFormat("en-US", num_format).format(this.state.total && (this.state.total.totalTurnover || 0.00))}</p>
                                            </div>
                                            <Fade duration={1000} triggerOnce>
                                                <DataTable
                                                    keyField="no"
                                                    data={rsData}
                                                    columns={columns}
                                                    activePage={this.state.activePage}
                                                    limit={this.state.limit}
                                                    totalItems={this.state.totalRecords}
                                                    paginationHandler={(val) => this.datatablesChangeHandler(val, 'betting_summary', "activePage")}
                                                    // sizePerPageHandler={(val) => this.datatablesChangeHandler(val, 'betting_summary', "limit")}
                                                    loading={this.state.loading}
                                                    hideSearchBar={true}
                                                    hideTotalRecords={true}
                                                    classValue="pd-table"
                                                    component="betting-history"
                                                />
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                            </Reveal>
                        </div>
                    </section>
                ) : (
                    <MobileBettingHistory
                        gameCategories={gameCategories}
                        gameProviders={gameProviders}
                        filterDate={filterDate}
                        onInputChange={this.onInputChange}
                        filterDayRange={this.filterDayRange}

                        icons={icons}
                        num_format={num_format}
                        rsData={rsData}
                        columns={columns}

                        searchHandler={this.searchHandler}
                        datatablesChangeHandler={this.datatablesChangeHandler}

                        onChangeDateHandler={this.onChangeDateHandler}
                        toggleShowDateSelect={this.toggleShowDateSelect}
                        handleGameProviderChange={this.handleGameProviderChange}

                        onResetHandler={this.onResetHandler}
                        onChangeFilterDateHandler={this.onChangeFilterDateHandler}

                        {...this.state}
                        {...this.props}
                    />
                )
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        rs: state.history.rs,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(BettingHistory));
