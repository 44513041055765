import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { isDesktop } from "react-device-detect";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Icon } from "@iconify/react";
import Skeleton from "react-loading-skeleton";
// import Swal from "sweetalert2";

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import QuickPay from "./QuickPay";
import BankTransfer from "./BankTransfer";
import EWallet from "./EWallet";
import MobileDeposit from "./MobileDeposit";
import Crypto from "./Crypto";

//Actions
import * as actions from "../../../store/ducks/deposit/actions";
import { storedDepositDropdownValidation, storedSecretPayBankOptionsValidation } from "../../../common/config/depositDataValidation";

class Deposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            depositOption: "",
            transactionChannel: [],
            transactionChannelId: "",
            loading: true,

            bankOfflineMin: 0,
            bankOfflineMax: 0,
            paymentTypeId: "",
            paymentGatewaySelected: "",
            cryptoChannel: "",
            cryptoType: "",
            channelId: "",
            paymentGateway: [],
            bankOfflineFees: 0,
            depositEstTime: "00:00:00",

            platform: "",
            bankTransferPaymentTypeId: {}, // use to store payment_type_id for bank-transfer (online_banking, secret_pay_offline)
        };
    }

    componentDidMount() {
        this.props.funcGetDropDown();

        let data = {};
        data.transType = "deposit";
        data.currencyCode = this.props.currentUser.currency;
        this.props.funcGetSecretPayBankOption(data);

        //Fingerprint
        const fpPromise = FingerprintJS.load();

        // Get the visitor identifier when you need it.
        fpPromise
            .then((fp) => fp.get())
            .then((result) => {
                this.setState({
                    platform: result.components.platform.value,
                });
            });
    }

    shouldComponentUpdate(nextProps, nextState) {
        // const { t } = this.props;
        if (nextProps.bank_offline_option !== this.props.bank_offline_option) {
            let to_store_data = storedSecretPayBankOptionsValidation(this.props.default_secretpay_bank_offline_options, nextProps.bank_offline_option.data);
            if (to_store_data.overwrite === true) {
                const storeOfflineOptions = {};
                storeOfflineOptions.data = nextProps.bank_offline_option.data;
                this.props.funcDefaultBankOfflineOptions(storeOfflineOptions);
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === "success") {
                let depositOption = this.state.depositOption;
                let transactionChannelId = this.state.transactionChannelId;
                let bankTransferPaymentTypeId = {};

                nextProps.dropDown.data.transaction_channel.forEach((val) => {
                    if (val.dp_status === 1 && depositOption === "") {
                        depositOption = depositOption === "" && val.dp_status === 1 ? val.slug : depositOption;
                        transactionChannelId = val.id;
                    }
                });

                let paymentTypeId = "";
                let paymentGatewaySelected = "";
                let cryptoType = "";
                let cryptoChannel = "";
                let channelId = "";

                if (depositOption === "bank-transfer") {
                    nextProps.dropDown.data.payment_gateway.forEach((val, index) => {
                        if(val.transaction_slug === depositOption) {
                            if (paymentTypeId === "" && val.dp_status === 1) {
                                paymentTypeId = val["id"];
                                paymentGatewaySelected = val["slug"];
                            }
                            if (val.slug === "secret_pay_offline" && val.dp_status === 1) {
                                if (this.props.currentUser.country_id === 1) {
                                    let data = {};
                                    data.currencyCode = this.props.currentUser.currency;
                                    this.props.funcGetBankOfflineOptions(data);

                                    this.setState({
                                        bankOfflineMin: val.transaction_minimum_payment_amount,
                                        bankOfflineMax: val.transaction_maximum_payment_amount,
                                        paymentTypeId: val.id,
                                        bankOfflineFees: val.fees,
                                    });
                                }
                            }
                            bankTransferPaymentTypeId[val.slug] = val.id;
                        }
                    });
                }

                if (depositOption === "quick-pay") {
                    nextProps.dropDown.data.payment_gateway.map((val) => {
                        if (val.transaction_slug === "quick-pay" && val.dp_status === 1) {
                            if (paymentTypeId === "") {
                                paymentTypeId = val["id"];
                                paymentGatewaySelected = val["slug"];
                            }
                        }
                    });
                }

                if (depositOption === "e-wallet") {
                    nextProps.dropDown.data.payment_gateway.map((val) => {
                        if (val.transaction_slug === "e-wallet" && val.dp_status === 1) {
                            if (paymentTypeId === "") {
                                paymentTypeId = val["id"];
                                paymentGatewaySelected = val["slug"];
                            }
                        }
                    });
                }

                if(depositOption === "crypto"){
                    nextProps.dropDown.data.payment_gateway.map((val) => {
                        if (val.transaction_slug === "crypto" && val.slug_details === "crypto-vendor" && val.dp_status === 1) {
                            if (paymentTypeId === "") {
                                paymentTypeId = val["id"];
                                paymentGatewaySelected = val["slug"];
                            }
                        }

                        else if (val.transaction_slug === "crypto" && val.dp_status === 1) {
                            if (channelId === "") {
                                channelId = val["id"];
                                cryptoType = val['slug_details'];
                                cryptoChannel = val['slug'];
                            }
                        }
                    });
                }

                this.setState({
                    depositOption: depositOption,
                    transactionChannel: nextProps.dropDown.data.transaction_channel,
                    transactionChannelId: transactionChannelId,
                    paymentTypeId: paymentTypeId,
                    paymentGatewaySelected: paymentGatewaySelected,
                    paymentGateway: nextProps.dropDown.data.payment_gateway,
                    cryptoChannel: cryptoChannel,
                    bankTransferPaymentTypeId,
                    channelId: channelId,
                    cryptoType: cryptoType,
                    loading: false
                });

                // store dropdown option for all the transaction channel
                let to_store_data = storedDepositDropdownValidation(this.props.default_dropdown_options, nextProps.dropDown.data);
                if (to_store_data.overwrite === true) {
                    const storeDropdown = {};
                    storeDropdown.data = nextProps.dropDown.data;
                    this.props.funcDefaultDropDownOptions(storeDropdown);
                }
            }
        }

        if (nextProps.bank_option !== this.props.bank_option) {
            if (nextProps.bank_option.responseCode === 200) {
                let to_store_data = storedSecretPayBankOptionsValidation(this.props.default_secretpay_bank_options, nextProps.bank_option.data);
                if (to_store_data.overwrite === true) {
                    const storeBankOptions = {};
                    storeBankOptions.data = nextProps.bank_option.data;
                    this.props.funcDefaultSecretPayBankOptions(storeBankOptions);
                }
            }
        }

        return true;
    }

    optionSelectHandler = (e, option, optionId, depositEstTime) => {
        e.preventDefault();
        let date = new Date();

        // Temporary hide for phase 2 only implement
        // if (option === "crypto") {
        //     this.props.funcGetAvailableCrypto();

        //     const cryptoData = {};
        //     cryptoData.crypto = "USDT";

        //     this.props.funcGetCrypto(cryptoData);
        // }

        let paymentTypeId = "";
        let paymentGatewaySelected = "";

        let bankTransferPaymentTypeId = {};
        let bankOfflinePaymentGatewaySelected = "";
        let bankOfflineMin = 0;
        let bankOfflineMax = 0;
        let bankOfflineFees = 0;

        let cryptoType = "";
        let cryptoChannel = "";
        let channelId = "";

        if (option === "quick-pay") {
            this.state.paymentGateway.map((val) => {
                if (val.transaction_slug === "quick-pay" && val.dp_status === 1) {
                    if (paymentTypeId === "") {
                        paymentTypeId = val["id"];
                        paymentGatewaySelected = val["slug"];
                    }
                }
            });
        }

        if (option === "bank-transfer") {
            this.state.paymentGateway.map((val, index) => {
                if(val.transaction_slug === option){
                    if (paymentTypeId === "" && val.dp_status === 1) {
                        paymentTypeId = val["id"];
                        paymentGatewaySelected = val["slug"];
                    }
                    if (val.slug === "secret_pay_offline" && val.dp_status === 1) {
                        let data = {};
                        data.currencyCode = this.props.currentUser.currency;
                        this.props.funcGetBankOfflineOptions(data);

                        bankOfflineMin = val["transaction_minimum_payment_amount"];
                        bankOfflineMax = val["transaction_maximum_payment_amount"];
                        bankOfflineFees = val["fees"];
                        bankOfflinePaymentGatewaySelected = val["slug"];
                    } else if (val.slug === "online_banking" && val.dp_status === 1) {
                        bankOfflinePaymentGatewaySelected = val["slug"];
                    }
                    bankTransferPaymentTypeId[val.slug] = val.id;
                }
            });
        }

        if(option === "crypto"){
            this.state.paymentGateway.map((val) => {
                if(val.transaction_slug === option){
                    if (paymentTypeId === "" && val.dp_status === 1) {
                        paymentTypeId = val["id"];
                        paymentGatewaySelected = val["slug"];
                    }

                    if (channelId === "" && val.dp_status === 1) {
                        channelId = val["id"];
                        cryptoType = val['slug_details'];
                        cryptoChannel = val['slug'];
                    }
                }
            });
        }

        this.setState({
            depositOption: this.props.depositOption !== option ? option : "",
            transactionChannelId: optionId,
            paymentTypeId: paymentTypeId,
            paymentGatewaySelected: paymentGatewaySelected,
            bankOfflinePaymentGatewaySelected: bankOfflinePaymentGatewaySelected,
            bankTransferPaymentTypeId: bankTransferPaymentTypeId,
            bankOfflineMin: bankOfflineMin,
            bankOfflineMax: bankOfflineMax,
            bankOfflineFees: bankOfflineFees,
            cryptoChannel: cryptoChannel,
            channelId: channelId,
            cryptoType: cryptoType,
            depositEstTime: depositEstTime
        });
    };

    redirectHandler = () => {
        this.props.history.push({
            pathname: "/transaction-history",
            state: {
                historyTab: "deposit",
                defaultTab: 0,
            },
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        let transactionChannel = this.state.transactionChannel ? this.state.transactionChannel : [];
        const icons = this.imageImport(require.context("../../../assets/images/profile/icons", false, /\.(png|jpe?g|svg)$/));
        return (
            <Auxiliary>
                {isDesktop ? (
                    <section className="pf-dashboard">
                        <div className="x-container">
                            <ProfileSideBar />
                            <div className="row">
                                <div className="col-12">
                                    <div className="pd-card-col d-flex flex-column h-100">
                                        <h4>{t("profile-sidebar.deposit")}</h4>
                                        <div className="pd-content mb-3">
                                            {this.state.loading ? (
                                                <Skeleton count={2} height={40} style={{marginBottom: "1rem"}} />
                                            ) : (
                                            <div className="d-grid gap-3">
                                                <div className="row">
                                                    <label className="col-3 col-form-label">{t("deposit.deposit-option")}:</label>
                                                    <div className="col-9 mb-3 select-bank-opts">
                                                        {transactionChannel.map((val, idx) => {
                                                            if (val.dp_status === 1) {
                                                                return (
                                                                    <div
                                                                        className="form-check form-check-inline"
                                                                        onClick={(event) => this.optionSelectHandler(event, val.slug, val.id)}
                                                                        key={idx}
                                                                    >
                                                                        <input
                                                                            className="btn-check"
                                                                            type="radio"
                                                                            name="depositOption"
                                                                            id={`deposit${val.slug}`}
                                                                            value={val.slug}
                                                                            onChange={() => {}}
                                                                            checked={this.state.depositOption === val.slug ? true : false}
                                                                        />
                                                                        <label className="btn form-check-label" htmlFor={`deposit${val.slug}`}>
                                                                            <img src={icons[`${val.slug}.svg`]} alt={val.slug} />
                                                                            <p>{t(`transaction-channel.${val.slug}`)}</p>
                                                                            {val.deposit_est_time && (
                                                                                
                                                                                    
                                                                                    <div className="transfer-duration">
                                                                                        {/* <Icon icon="ic:baseline-alarm" />
                                                                                        Deposit in 1 min */}
                                                                                        {val.deposit_est_time === "00:00:00" ? (
                                                                                            <>
                                                                                                <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                                {t("deposit.instant-deposit")}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <Icon icon="ic:baseline-alarm" />
                                                                                                {t("deposit.deposit-in")} {val.deposit_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                                            </>
                                                                                        )}
                                                                                        
                                                                                    </div>
                                                                                    
                                                                                )}
                                                                        </label>
                                                                    </div>
                                                                    
                                                                );
                                                            }
                                                            
                                                            // else
                                                            // {
                                                            return null;
                                                            // }
                                                        })}
                                                    </div>
                                                    {this.state.depositOption === "quick-pay" ? (
                                                        <QuickPay redirectHandler={this.redirectHandler} {...this.state} />
                                                    ) : this.state.depositOption === "bank-transfer" ? (
                                                        <BankTransfer redirectHandler={this.redirectHandler} {...this.state} />
                                                    ) : this.state.depositOption === "e-wallet" ? (
                                                        <EWallet redirectHandler={this.redirectHandler} {...this.state} />
                                                    ) : this.state.depositOption === "crypto" ? (
                                                        <Crypto redirectHandler={this.redirectHandler} {...this.state} />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                           )}
                                        </div>
                                        <div className="payment-notices-col">
                                            <h6>{t("deposit.notice")}</h6>
                                            <ol>
                                                <li>{t("deposit.deposit-notice-content-1")}</li>
                                                <li>{t("deposit.deposit-notice-content-2")}</li>
                                                <li>
                                                    <span>{t("deposit.deposit-notice-content-title-3")}</span> {t("deposit.deposit-notice-content-3")}
                                                </li>
                                                <li>
                                                    {t("deposit.deposit-notice-content-4")}
                                                    <span>{t("deposit.deposit-notice-content-title-4")}</span>
                                                    {t("deposit.deposit-notice-content-4-cont")}
                                                </li>
                                                <li>{t("deposit.deposit-notice-content-5")}</li>
                                                <li>
                                                    {t("deposit.deposit-notice-content-6")}
                                                    <a href="/" onClick={window["liveChatButton"]}>
                                                        {t("deposit.deposit-notice-content-title-6")}
                                                    </a>
                                                    {t("deposit.deposit-notice-content-6-cont")}
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : (
                    <MobileDeposit
                        optionSelectHandler={this.optionSelectHandler}
                        redirectHandler={this.redirectHandler}
                        transactionChannel={transactionChannel}
                        {...this.state}
                        {...this.props}
                    />
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        dropDown: state.deposit.dropDown,
        currentUser: state.currentUser.data,
        bank_option: state.deposit.bank_option,
        default_dropdown_options: state.deposit.default_dropdown_options,
        default_secretpay_bank_options: state.deposit.default_secretpay_bank_options,
        bank_offline_option: state.deposit.bank_offline_option,
        default_secretpay_bank_offline_options: state.deposit.default_secretpay_bank_offline_options,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcGetSecretPayBankOption: (data) => dispatch(actions.getSecretPayBankOption(data)),
        funcGetBankOfflineOptions: (data) => dispatch(actions.getSecretPayBankOfflineOption(data)),
        funcDefaultDropDownOptions: (data) => dispatch(actions.defaultDropDownOptions(data)),
        funcDefaultSecretPayBankOptions: (data) => dispatch(actions.defaultSecretPayBankOptions(data)),
        funcDefaultBankOfflineOptions: (data) => dispatch(actions.defaultSecretPayBankOfflineOptions(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Deposit));
